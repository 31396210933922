import React from "react";
import Layout from '../components/layout';

const About = () => (
  <Layout>
    <h1>About me</h1>
	<div className="introduction">
		<p>
			Bonjour, Moi c'est Benjamin Lienart, Développeur Full stack à plein temps , passionner par le web et les technologie, j'aime partager avec mes paires et apprendre tous les jours. <br/><br/>
			
			J'ai donc créer ce blog afin de partager mes expériences et connaissances mais aussi à destination du concept "Learn in public" (plus d'infos ici https://www.swyx.io/writing/learn-in-public/).
		</p>
	</div>

	<div className="aboutBlock">
			<h2>Moi en quelques points :</h2> 
			<ul className="about-list">
				<li><span role="img" aria-label="cyclist">🚴</span> / Vélo Addict</li>
				<li><span role="img" aria-label="Ordinateur">💻</span> / Front/End Developpeur à plein temps </li>
				<li><span role="img" aria-label="Binsh">🍻</span> / Beers Lover </li>
				<li><span role="img" aria-label="Bib">🍼</span> / Papa Gaga </li>
				<li><span role="img" aria-label="Jeux video">👾</span> / Retro Gaming (Tu connais Blooby Volley ?)</li>
				<li><span role="img" aria-label="Cansque audio">🎧</span> / Jamais sans musique</li>
				<li><span role="img" aria-label="Asiatique">🍱</span> / Le japonais et les brioches c'est la vie</li>
			</ul>
	</div>
	<div className="aboutBlock">
		To be contuned... 
	</div>
  </Layout>
);

export default About;